import React from "react";

import SocialNetworks from "../components/SocialNetworks";

import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <div name="contact" className="contact" id="contact">
      <div>
        <div className="contact__title">{t("contactPage.title")}</div>
        <div className="contact__email">
          <div>belgrade@pointlessfilms.rs</div>
        </div>
      </div>
      <div className="contact__networks">
        <SocialNetworks />
      </div>
    </div>
  );
};

export default Contact;
