import React from "react";

import { useTranslation } from "react-i18next";

const Image = ({ image }) => {
  const { t } = useTranslation();

  const { source, title1, title2, route } = image;
  let imageSource = source;
  const { innerWidth: width, innerHeight: height } = window;
  if (width > height) {
    imageSource = `${source}_h`;
  }

  return (
    <div>
      <div
        className="video-content"
        style={{
          background: "linear-gradient(to bottom, gray, #888, black)",
          opacity: "0.6",
        }}
      >
        <div className="video-content__bottom" style={{ opacity: "1" }}>
          <a
            href={`/${route}`}
            style={{ textDecoration: "none", color: "white" }}
          >
            <div className="subtitle">
              {t(title1)} {title2 ? <br /> : ""} {t(title2)}
            </div>
          </a>
          <div className="subtitle">
            <a href={`/${route}`}>
              <img src="/images/plus.svg" alt="pointless" />
            </a>
          </div>
        </div>
      </div>
      <img
        className="video"
        id="img-gradient"
        src={`/images/izvanFilma/${imageSource}.png`}
        alt="pointless"
      />
    </div>
  );
};

export default Image;
