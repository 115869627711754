import React from "react";

import Video from "../components/Video";

import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const videos = [
  {
    source: "video2",
    title1: "dashboardPage.title2",
    subtitle1: "dashboardPage.subtitle2",
    route: "sunce-nikad-vise",
    img: "/images/izvanFilma/Vertical/sunce1.png",
    key: 1,
  },
  {
    source: "video1",
    title1: "dashboardPage.title1",
    subtitle1: "dashboardPage.subtitle1",
    route: "ovuda-ce-proci-put",
    img: "images/naslovneVideo/ocpp_ver.png",
    key: 2,
  },
  {
    source: "video3",
    title1: "dashboardPage.title31",
    title2: "dashboardPage.title32",
    subtitle1: "dashboardPage.subtitle3",
    route: "euforija-uzivo",
    img: "/images/naslovneVideo/bk_ver.png",
    key: 3,
  },
  {
    source: "video4",
    title1: "dashboardPage.title4",
    subtitle1: "dashboardPage.subtitle4",
    route: "ubistvo-radi-ubistva",
    img: "/images/naslovneVideo/uru_ver.png",
    key: 4,
  },
];

const Dasboard = () => {
  return (
    <div name="home">
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        slidesPerView={1}
        loop
        speed={1000}
        autoplay={{ delay: 35000, disableOnInteraction: false }}
        className="swiper"
        navigation
        // pagination={{ clickable: true }}
      >
        {videos.map((video) => (
          <SwiperSlide key={video.key}>
            <Video video={video} key={video.key} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Dasboard;
