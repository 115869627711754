import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

const Manifest = () => {
  const { t } = useTranslation();
  const [isVertical, setVertical] = useState(true);
  const [imgVideo, setImgVide] = useState(true);

  useEffect(() => {
    const { innerWidth: width, innerHeight: height } = window;
    if (width > height) {
      setVertical(false);
    } else {
      setVertical(true);
    }
  }, []);

  const playVideo = () => {
    setImgVide(false); // Ovo će promeniti stanje kako bi sakrilo sliku i pokazalo video
  };

  let heightVideo = "auto";
  let paddingVideo = "5vh";
  const { innerWidth: width, innerHeight: height } = window;
  if (width > height) {
    heightVideo = "75vh";
    paddingVideo = "0px";
  }

  return (
    <div
      name="manifest"
      className="manifest"
      id="manifest-page"
      style={{ zIndex: 1000 }}
    >
      <div id="manifest" style={{ zIndex: 1000 }}>
        <div className="manifest__title">{t("manifestPage.title")}</div>
        <div className="manifest__reel">
          <div
            className="video-container"
            style={{
              height: heightVideo,
              paddingTop: paddingVideo,
              zIndex: "10",
            }}
          >
            {!imgVideo ? (
              <iframe
                src="https://player.vimeo.com/video/384887237?h=88121a3e9b&autoplay=1&muted=1&loop=1"
                width="80%"
                height="100%"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                title="pointless"
              ></iframe>
            ) : (
              <div className="manifest-div-video-img">
                <img
                  src="/images/play.svg"
                  alt="pointless"
                  className="play-video-btn-manifest"
                  onClick={playVideo}
                />
                <img
                  className="img-manifset"
                  src={
                    isVertical
                      ? "/images/naslovneVideo/manifest_ver.png"
                      : "/images/naslovneVideo/manifest_hor.png"
                  }
                  alt="pointless"
                />
              </div>
            )}
          </div>
          {/* <div style={{ paddingTop: "10px" }}>
            <a href="https://vimeo.com/384887237">About</a> from{" "}
          </div>
          <div>
            <a href="https://vimeo.com/pointlessfilms">Pointless Films</a> on{" "}
          </div>
          <div>
            <a href="https://vimeo.com">Vimeo</a>.
          </div> */}
        </div>
        <div className="manifest__subtitle" style={{ paddingTop: "30px" }}>
          {t("manifestPage.subtitle")}
        </div>
      </div>
      <div className="manifest__text" style={{ paddingTop: "20px" }}>
        <div>{t("manifestPage.textTitle")}</div>
        <div>{t("manifestPage.text")}</div>
      </div>
    </div>
  );
};

export default Manifest;
