import React, { useEffect } from "react";
import lottie from "lottie-web";

const AnimatedLogo = () => {
  useEffect(() => {
    const animationData = require("/home/igor/Desktop/pointless/src/assets/data.json");
    lottie.loadAnimation({
      container: document.querySelector("#lottie"), // referenca na div element
      animationData: animationData,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });

    return () => lottie.destroy();
  }, []);

  return <div id="lottie"></div>;
};

export default AnimatedLogo;
