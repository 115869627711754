import React from "react";

import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { BsVimeo } from "react-icons/bs";

const SocialNetworks = () => {
  return (
    <div>
      <a
        href="https://vimeo.com/pointlessfilms"
        target="_blank"
        rel="noreferrer"
      >
        <BsVimeo />
      </a>
      <a
        href="https://www.instagram.com/pointless__films/"
        target="_blank"
        rel="noreferrer"
      >
        <FaInstagram />
      </a>
      <a
        href="https://www.facebook.com/p/Pointless-Films-100064434955838/"
        target="_blank"
        rel="noreferrer"
      >
        <FaFacebookF />
      </a>
    </div>
  );
};

export default SocialNetworks;
