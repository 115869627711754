import React from "react";

import {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Image from "../components/Image";

const images = [
  {
    source: "slika1",
    title1: "beyondFilm1",
    route: "pointless-basta",
    key: 1,
  },
  {
    source: "slika2",
    title1: "beyondFilm21",
    title2: "beyondFilm22",
    route: "pointless-dosije",
    key: 2,
  },
  {
    source: "slika3",
    title1: "beyondFilm3",
    route: "yugodiskac",
    key: 3,
  },
  {
    source: "slika4",
    title1: "beyondFilm4",
    route: "ruzin-jelovnik",
    key: 4,
  },
  {
    source: "slika5",
    title1: "beyondFilm5",
    route: "zanko",
    key: 5,
  },
];
const Actions = () => {
  return (
    <div name="actions">
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        slidesPerView={1}
        loop
        navigation
        autoplay={{ delay: 8000, disableOnInteraction: false }}
        speed={1000}
        className="swiper"
      >
        {images.map((image) => (
          <SwiperSlide key={image.key}>
            <Image image={image} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Actions;
