import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

const Video = ({ video }) => {
  const { t } = useTranslation();
  const [isVertical, setVertical] = useState(true);
  const { source, title1, title2, subtitle1, route } = video;
  let videoSource = source;
  const { innerWidth: width, innerHeight: height } = window;
  if (width > height) {
    videoSource = `${source}_h`;
  }

  useEffect(() => {
    const { innerWidth: width, innerHeight: height } = window;
    if (width > height) {
      setVertical(false);
    } else {
      setVertical(true);
    }
  }, []);

  return (
    <div key={video.key}>
      <div className="video-content">
        <div className="video-content__bottom">
          <div className="year">{t(subtitle1)}</div>
          <a
            href={`/${route}`}
            style={{ textDecoration: "none", color: "white" }}
          >
            <div className="subtitle">
              {t(title1)}
              {title2 ? <br /> : ""} {t(title2)}
            </div>
          </a>
          <div className="subtitle">
            <a href={`/${route}`}>
              <img src="/images/plus.svg" alt="pointless" />
            </a>
          </div>
        </div>
      </div>
      {isVertical ? (
        <div>
          <img className="img-homepage-mobile" src={video.img} alt="Poinless" />
        </div>
      ) : (
        <div>
          <video
            className="video"
            preload="true"
            autoPlay
            muted
            loop
            playsInline
            id="vid"
          >
            <source src={`/videos/${videoSource}.mp4`} type="video/mp4" />
          </video>
        </div>
      )}
    </div>
  );
};

export default Video;
