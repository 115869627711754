import React, { useEffect } from "react";

import Dasboard from "./Dashboard";
import Manifest from "./Manifest";
import Actions from "./Actions";
import Contact from "./Contact";
import Header from "../components/Header";
import Reklame from "./Reklame";
import { useLocation } from "react-router-dom";
import AnimatedLogo from "../components/AnimatedLogo";

const Homepage = () => {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const section = document.querySelector("#manifest-page");
  //     const { bottom } = section.getBoundingClientRect();

  //     if (
  //       bottom >= window.innerHeight / 2 &&
  //       bottom <= document.body.clientHeight - (7 * window.innerHeight) / 2
  //     ) {
  //       setLogoView(false);
  //     } else {
  //       setLogoView(true);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  return (
    <div>
      <Header />
      {/* {isLogoView ? (
        <img src="/logo_crni.png" alt="logo" className="logo" id="logo-crni" />
      ) : (
        ""
      )} */}
      {/* <div
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        className="logo"
        id="logo-crni"
      /> */}
      <AnimatedLogo />
      <Dasboard />
      <Actions />
      <Reklame />
      <Manifest />
      <Contact />
    </div>
  );
};

export default Homepage;
