import React from "react";

import SocialNetworks from "./SocialNetworks";
import { FaChevronDown } from "react-icons/fa";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

export const films = [
  {
    route: "sunce-nikad-vise",
    name: "film2",
    key: 1,
  },
  {
    route: "ovuda-ce-proci-put",
    name: "film1",
    key: 2,
  },
  {
    route: "euforija-uzivo",
    name: "film3",
    key: 3,
  },
  {
    route: "ubistvo-radi-ubistva",
    name: "film4",
    key: 4,
  },
];

export const beyondFilms = [
  {
    route: "pointless-basta",
    name: "beyondFilm1",
    key: 1,
  },
  {
    route: "pointless-dosije",
    name: "beyondFilm2",
    key: 2,
  },
  {
    route: "yugodiskac",
    name: "beyondFilm3",
    key: 3,
  },
  {
    route: "ruzin-jelovnik",
    name: "beyondFilm4",
    key: 4,
  },
  {
    route: "zanko",
    name: "beyondFilm5",
    key: 5,
  },
];

export const advertisements = [
  {
    route: "holofit",
    name: "reklama1",
    key: 1,
  },
  {
    route: "dmus",
    name: "reklama2",
    key: 2,
  },
];

const Header = ({ page }) => {
  const { t, i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenNavbar, setIsOpenNavbar] = useState(false);
  const [isOpenNavbarBeyondFilm, setIsOpenNavbarBeyondFilm] = useState(false);
  const [isOpenNavbarReklama, setIsOpenNavbarReklama] = useState(false);

  const headerClass = classNames("header", {
    "header-hide": !isOpen,
  });

  const headerLogoClass = classNames("header-logo", {
    "header-logo-hide": !isOpen,
  });

  const onChangePage = () => {
    setIsOpen(false);
  };

  const onOpenNavbar = () => {
    setIsOpenNavbar(!isOpenNavbar);
  };

  const onOpenNavbarBeyondFilm = () => {
    setIsOpenNavbarBeyondFilm(!isOpenNavbarBeyondFilm);
  };

  const onOpenNavbarReklama = () => {
    setIsOpenNavbarReklama(!isOpenNavbarReklama);
  };

  const onChangeLanguage = (language) => {
    localStorage.setItem("language", language);
    i18n.changeLanguage(language);
  };

  return (
    <div>
      {page === "film" ? (
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={headerLogoClass}
          style={{
            backgroundColor: "white",
            borderColor: "white",
            color: "white",
          }}
        ></button>
      ) : (
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={headerLogoClass}
        ></button>
      )}
      <div className={headerClass}>
        <div className="header-content">
          <div>
            <a href="/" style={{ textDecoration: "none", color: "white" }}>
              <span className="header-content__title">
                POINTLESS
                <br />
                FILMS
              </span>
            </a>
            <div className="header-content__items">
              <div
                onClick={() => onOpenNavbar()}
                key={"films"}
                className="header-content__items__item"
              >
                {t("films")} <FaChevronDown />
              </div>
              {isOpenNavbar
                ? films.map((item) => (
                    <a
                      key={item.key}
                      href={item.route}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <div
                        onClick={() => onChangePage()}
                        key={item.key}
                        className="header-content__items__item__navbar"
                      >
                        {t(item.name)}
                      </div>
                    </a>
                  ))
                : ""}

              <div
                onClick={() => onOpenNavbarBeyondFilm()}
                key={"outsideTheFilm"}
                className="header-content__items__item"
              >
                {t("outsideTheFilm")} <FaChevronDown />
              </div>
              {isOpenNavbarBeyondFilm
                ? beyondFilms.map((item) => (
                    <a
                      key={item.key}
                      href={item.route}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <div
                        onClick={() => onChangePage()}
                        key={item.key}
                        className="header-content__items__item__navbar"
                      >
                        {t(item.name)}
                      </div>
                    </a>
                  ))
                : ""}

              <div
                onClick={() => onOpenNavbarReklama()}
                key={"advertisements"}
                className="header-content__items__item"
              >
                {t("advertisements")} <FaChevronDown />
              </div>
              {isOpenNavbarReklama
                ? advertisements.map((item) => (
                    <a
                      key={item.key}
                      href={item.route}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <div
                        onClick={() => onChangePage()}
                        key={item.key}
                        className="header-content__items__item__navbar"
                      >
                        {t(item.name)}
                      </div>
                    </a>
                  ))
                : ""}

              <a
                href="/#manifest"
                style={{ textDecoration: "none", color: "white" }}
                onClick={() => onChangePage()}
              >
                <div key={"manifest"} className="header-content__items__item">
                  {t("manifest")}
                </div>
              </a>
              <a
                href="/#contact"
                onClick={() => onChangePage()}
                style={{ textDecoration: "none", color: "white" }}
              >
                <div key={"contact"} className="header-content__items__item">
                  {t("contact")}
                </div>
              </a>
            </div>
          </div>
          <div className="header-content__bottom">
            <div className="header-content__bottom__left">
              <span
                onClick={() => onChangeLanguage("en")}
                style={{ marginRight: 15 }}
              >
                eng
              </span>{" "}
              |{" "}
              <span
                onClick={() => onChangeLanguage("rs")}
                style={{ marginLeft: 15 }}
              >
                srb
              </span>
            </div>
            <div className="header-content__bottom__right">
              <SocialNetworks />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
